<template>
    <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="true">
      <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
        class="filter-from">
       
        <!-- 模块&用户名&搜索 -->
        <div class="filter-item">
          <el-form-item label="问卷名称：" prop="keyword">
            <el-input v-model="form.keyword" placeholder="请输入问卷名称"></el-input>
          </el-form-item>
        </div>
        <div class="filter-item">
          <DatePeriodPicker label="创建时间：" start-prop="create_start_time" :start-time.sync="form.create_start_time"
            end-prop="create_end_time" :end-time.sync="form.create_end_time" />
        </div>
      </el-form>
    </LayoutFilter>
  </template>
  <script>
  import LayoutFilter from "@/base/layout/LayoutFilter";
  import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
  export default {
    props: {
      uploadFilter: {
        type: Function,
        required: true,
      },
      filterForm: {
        type: Object,
      },
      page: {
        type: Number,
        default: 1,
      },
    },
    data() {
      return {
        rules: {},
      };
    },
    methods: {
      //重置表单
      resetForm() {
        this.$refs["logFilterForm"].resetFields();
        this.uploadFilter(this.form);
      },
      //筛选
      ok() {
        this.uploadFilter(this.form);
      },
      // 导出
      // onExport() {
      //   const postData = { ...this.form, page: this.page };
      //   return topicsExport(postData)
      //     .then()
      //     .catch(() => {});
      // },
    },
    created() {},
    computed: {
      form: {
        get() {
          return this.filterForm;
        },
        set(val) {},
      },
    },
    components: {
      LayoutFilter,
      DatePeriodPicker,
    },
  };
  </script>
<template>
  <div class="list-page">
    <div style="margin-bottom: 20px">
      <el-button type="primary" size="small" @click="showAddSurvey = true"
        >新增问卷</el-button
      >
    </div>
    <list-filter
      :filterForm="filterForm"
      :page="pageData.current_page"
      :uploadFilter="ok"
    />
    <list-tabs
      v-model="filterForm.tab"
      :tabs="tabArr"
      :isLoading="loading"
      @tab-click="handleClick"
    >
    </list-tabs>
    <div class="list-content" v-loading="loading">
      <div class="list-btn">
        <el-button
          v-if="filterForm.tab === 'deleted'"
          type="success"
          size="small"
          @click="handleReduct"
        >
          还原
        </el-button>
        <el-button type="danger" size="small" @click="handleDel"
          >删除
        </el-button>
      </div>
      <el-table
        :data="tbody"
        class="thead-light"
        stripe
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <!-- 勾选-->
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <!-- 操作 -->
        <el-table-column
          label="操作"
          :width="filterForm.tab !== 'deleted' ? 150 : 120"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button-group>
              <el-button
                v-if="filterForm.tab !== 'deleted'"
                size="small"
                @click="editRowForm(scope.row)"
                type="text"
              >
                设计
              </el-button>
              <el-button
                v-if="filterForm.tab !== 'deleted'"
                size="small"
                @click="editRow(scope.row)"
                type="text"
              >
                编辑
              </el-button>
              <el-button
                v-if="filterForm.tab === 'deleted'"
                size="small"
                @click="ReductRow(scope.row)"
                type="text"
              >
                还原
              </el-button>
              <el-button size="small" @click="deleteRow(scope.row)" type="text"
                >删除</el-button
              >
            </el-button-group>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column
            :prop="th.prop"
            :label="th.label"
            :min-width="th.minWidth || ''"
            :key="index"
            :sortable="th.sortable"
          >
            <template slot-scope="scope">
              <span>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    />
    <AddSurvey
      :showAddSurvey="showAddSurvey"
      :editObj="editObj"
      @closeAddDialog="closeAddDialog"
      @updateData="updateData"
    />
  </div>
</template>

<script>
import Pagination from '@/base/components/Default/Pagination'
import AddSurvey from '@/modules/consult/components/ConsultService/AddSurvey'
import {
  getSurveyIndex,
  softDelete,
  Delete,
  putBack,
} from '@/modules/consult/api/consult-service/survey-list.js'
import consultServiceSurveyList from '../../mixin/consultServiceSurveyList'
import listFilter from '@/modules/consult/components/consult-service/survey-list/ListFilter.vue'
export default {
  mixins: [consultServiceSurveyList],
  data() {
    return {
      tabArr: [
        { label: '全部', name: 'normal' },
        { label: '回收站', name: 'deleted' },
      ],
      loading: false, //加载

      pageData: {
        page_size: 15,
        tab: 'normal',
      },
      tbody: [],
      selectArr: [], //勾选的数组id
      editObj: {},
      showAddSurvey: false,
    }
  },
  methods: {
    // 获取列表
    getList(pageData) {
      this.tbody = []
      this.pageData = {}
      this.loading = true
      getSurveyIndex(pageData)
        .then((res) => {
          const { data } = res
          this.tbody = data.data
          this.pageData = data
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = []
      } else {
        this.selectArr = []
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id)
        })
      }
    },
    // 批量删除
    handleDel() {
      if (this.selectArr.length) {
        if (this.filterForm.tab !== 'deleted') {
          this.$msgbox
            .confirm('确定要将选中数据移入回收站吗？', '提示', {
              type: 'info',
            })
            .then((res) => {
              this.handleDelete({ ids: this.selectArr })
            })
            .catch((err) => {})
        } else {
          this.$msgbox
            .confirm('确定要将选中数据永久删除吗？', '提示', {
              type: 'error',
            })
            .then((res) => {
              this.handleDelete({ ids: this.selectArr })
            })
            .catch((err) => {})
        }
      } else {
        this.$message.info('请选择数据项')
      }
    },
    // 点击列表删除
    deleteRow(row) {
      if (this.filterForm.tab !== 'deleted') {
        this.$msgbox
          .confirm('确定要将此数据移入回收站吗？', '提示', {
            type: 'info',
          })
          .then((res) => {
            this.handleDelete({ ids: [row.id] })
          })
          .catch((err) => {})
      } else {
        this.$msgbox
          .confirm('确定要将此数据永久删除吗？', '提示', {
            type: 'error',
          })
          .then((res) => {
            this.handleDelete({ ids: [row.id] })
          })
          .catch((err) => {})
      }
    },
    // 数据删除
    handleDelete(ids) {
      this.loading = true
      if (this.filterForm.tab !== 'deleted') {
        softDelete(ids)
          .then((res) => {
            this.$message.success(res.msg)
            this.refreshList()
          })
          .catch((err) => {
            this.loading = false
          })
      } else {
        Delete(ids)
          .then((res) => {
            this.$message.success(res.msg)
            this.refreshList()
          })
          .catch((err) => {
            this.loading = false
          })
      }
    },
    // 批量还原数据
    handleReduct() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm('确定要将选中数据还原吗？', '提示', {
            type: 'info',
          })
          .then((res) => {
            this.Reduct({ ids: this.selectArr })
          })
          .catch((err) => {})
      } else {
        this.$message.info('请选择数据项')
      }
    },
    // 点击列表还原
    ReductRow(row) {
      this.$msgbox
        .confirm('确定要将此数据还原吗？', '提示', {
          type: 'info',
        })
        .then((res) => {
          this.Reduct({ ids: [row.id] })
        })
        .catch((err) => {})
    },
    // 还原数据
    Reduct(ids) {
      this.loading = true
      putBack(ids)
        .then((res) => {
          this.$message.success(res.msg)
          this.refreshList()
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 点击编辑
    editRow(row) {
      this.editObj = row
      this.showAddSurvey = true
    },
    editRowForm(row) {
      let routerData = this.$router.resolve({
        name: 'ConsultServiceSurveyForm',
        params: {
          id: row.id,
        },
        query: {
          title: encodeURIComponent(row.name),
        },
      })
      window.open(routerData.href, '_blank')
    },
    closeAddDialog() {
      this.showAddSurvey = false
      this.editObj = {}
    },
    // 切换tab栏
    handleClick(e) {
      this.getList(this.filterForm)
    },
    // 刷新列表
    refreshList() {
      this.getList({
        page: this.pageData.current_page,
        ...this.filterForm,
      })
    },
    // 筛选回调
    ok(e) {
      this.filterForm = { ...this.filterForm, ...e, page: 1 }
      this.getList({
        ...this.filterForm,
      })
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size
      const pageData = { ...this.filterForm, ...e }
      this.getList(pageData)
    },
    updateData() {
      this.showAddSurvey = false
      this.refreshList()
    },
  },
  created() {
    this.getList(this.filterForm) //获取列表数据
  },
  components: {
    Pagination,
    AddSurvey,
    listFilter,
  },
}
</script>

<style lang="scss" scoped></style>
